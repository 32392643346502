<template>
    <div class="white pa-5">
                   <VersatileFilter :columnsSearch="columsConfig" :filterEndpoint="$API.invoices.productsSalesFilterHistory" :search="search" @emptyFilter="getAllHistory"
                 @filtered="filterHandler"
                        :key="Reloadfilter" :columnfilterEndpoint="$API.invoices.productsSalesFilterHistoryColumn"
                        :radioSelect="radioSelect" :defaulSelect="radioSelect[0].value" 
                >
       
            <DxDataGrid 
                :dataSource="DataTable"
               
                :showBorders="true"
                :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
                @exporting="onExporting"
            >
                <DxColumn width="100px" data-field="InvoiceDate" caption="Fecha" ></DxColumn >
                <DxColumn width="100px" dataField="IdentificacionHorEmi" caption="Hora" />
                <DxColumn width="100px" dataField="CodigoGeneracion" caption="Codigo de generacion"  />
                <DxColumn width="100px" dataField="ID_Invoice" caption="Factura"  />
                <DxColumn width="100px" dataField="ID_Customer" caption="ID Cliente" />
                <DxColumn width="100px" dataField="CustomerName" caption="Nombre Cliente" />
                <DxColumn width="100px" dataField="UserName" caption="Emisor" />
                <DxColumn width="100px" dataField="ID_Item" caption="ID Producto"  />
                <DxColumn width="100px" dataField="ItemName" caption="Nombre Producto"  />
                <DxColumn width="100px" dataField="Familia" caption="Familia" />
                <DxColumn width="100px" dataField="Categoria" caption="Categoria"  />
                <DxColumn width="100px" dataField="SubCategoria" caption="Sub Categoria"  />
                <DxColumn width="100px" dataField="Conteo" caption="Cantidad"  />
                <DxColumn width="150px" dataField="PrecioUnidadShow" caption="Precio Unitario"  />
                <DxColumn width="100px" dataField="DescuentoShow" caption="Descuento"  />
                <DxColumn width="100px" dataField="ExentaShow" caption="Exenta"  />
                <DxColumn width="100px" dataField="TotalShow" caption="Total"  />
                <DxColumn width="100px" dataField="providerName" caption="Proveedores"  />
                <DxColumn width="100px" dataField="Warehouse" caption="Almacen"  />
                <DxColumn width="100px" dataField="SellerName" caption="Vendedor"  />
                <DxColumn width="100px" dataField="ID_ItemBatch" caption="Lote"  />
                <DxColumn width="100px" dataField="inventory_account_code" caption="Cuenta de inventarios"  />
                <DxColumn width="100px" dataField="sales_account_code" caption="Cuenta costo de venta" />
                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                <DxPaging :page-size="10" />
                <DxExport :enabled="true" />
            </DxDataGrid>
        </VersatileFilter>
    </div>
</template>

<script> 
    
    //import {mapState} from 'vuex';
    import {DxDataGrid, DxPager, DxPaging,DxColumn , DxExport} from 'devextreme-vue/data-grid';
   // import DateFilter from '@/components/DateFilter.vue';
   // import GeneralFilter from '@/components/GeneralFilter.vue';
    import { numberWithCommas } from '@/helpers/money';
    import { printToExcel } from "@/helpers/printToexcel";
    import VersatileFilter from '@/components/VersatileFilter';

 

    export default {
        name: "SalesHistory",
        components: {
            DxDataGrid,
            DxExport,
            DxPager,
          //  DateFilter,
            DxPaging,
            //GeneralFilter,
            DxColumn ,
            VersatileFilter
        },
        props: ['win','datafilter'],
        data() {
            return {
                DataTable: [],
                panel: 0,
                tab: null,
                key: 0,
                search:{
                    filter: null,
                },
                isFiltered: false,
                loading: false,
                Reloadfilter: 0,
                columsConfig: [
                    {
                        name: 'ID_Warehouse',
                        title: 'Almacenes',
                        value: '',
                        type: 'select',
                        options: [],
                    },
                    {
                        name: 'providers_id',
                        title: 'Proveedores',
                        value: '',
                        type: 'select',
                        options: [],
                    },
                    {
                        name: "Id_family",
                        title: "Familia",
                        value: '',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "Id_category",
                        title: "Categoría",
                        value: '',
                        parent: 'Id_family',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "Id_subcategory",
                        parent: 'Id_category',
                        title: "Subcategoría",
                        value: '',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "ID_ItemBatch",
                        title: "Lote",
                        value: '',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "ID_Invoice_TypesLike",
                        title: "Tipo de factura",
                        value: '',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "ID_Seller",
                        title: "Vendedor",
                        value: '',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "ID_Item",
                        title: "ID Producto",
                        value: '',
                        type: "text",
                    },
                    {
                        name: "ID_Invoice",
                        title: "Factura",
                        value: '',
                        type: "text",
                    },
                    {
                        name: "CodigoGeneracion",
                        title: "Codigo de generacion",
                        value: '',
                        type: "text",
                    },
                    {
                        name: "ItemName",
                        title: "Nombre Producto",
                        value: '',
                        type: "text",
                    },
                    
                    
                ],
                radioSelect: [
                    {
                        name: 'Todos',
                        value: 'All'
                    },
                    {
                        name: 'Productos',
                        value: 'Product'
                    },
                    {
                        name: 'Servicios',
                        value: 'Service'
                    }
                ]

                
                
            }
        },
        computed:{
            
        },
        methods: {
            onExporting(e){
                printToExcel(e, 'Historial de ventas')
            },
            getAllHistory(){
                this.isFiltered = false;
                this.loading = true;
                this.$API.invoices.getProductsSalesHistory()
                .then((response) => {
                    this.loading = false;
                    const { Currency } = JSON.parse(localStorage.getItem('branch'));
                    for(const index in response){
                        response[index].TotalShow = `${Currency}${numberWithCommas(response[index].Total.toFixed(2))}`;
                        response[index].ExentaShow = `${Currency}${numberWithCommas(response[index].Exenta.toFixed(2))}`;
                        response[index].DescuentoShow = `${Currency}${numberWithCommas(response[index].Descuento.toFixed(2))}`;
                        response[index].PrecioUnidadShow = `${Currency}${numberWithCommas(response[index].PrecioUnidad.toFixed(2))}`;
                    }
                    this.DataTable = response;
                   
                })
                .catch((err) => {
                    console.log(err);
                })
            },
            filterHandler(response) {
                this.isFiltered = true;
                const { Currency } = JSON.parse(localStorage.getItem('branch'));
                    for(const index in response){
                        response[index].TotalShow = `${Currency}${numberWithCommas(response[index].Total.toFixed(2))}`;
                        response[index].ExentaShow = `${Currency}${numberWithCommas(response[index].Exenta.toFixed(2))}`;
                        response[index].DescuentoShow = `${Currency}${numberWithCommas(response[index].Descuento.toFixed(2))}`;
                        response[index].PrecioUnidadShow = `${Currency}${numberWithCommas(response[index].PrecioUnidad.toFixed(2))}`;
                    }
                    this.DataTable = response;
                this.DataTable = response
            },
            dateFilterHandler(response) {
                const { Currency } = JSON.parse(localStorage.getItem('branch'));
                    for(const index in response){
                        response[index].TotalShow = `${Currency}${numberWithCommas(response[index].Total.toFixed(2))}`;
                        response[index].ExentaShow = `${Currency}${numberWithCommas(response[index].Exenta.toFixed(2))}`;
                        response[index].DescuentoShow = `${Currency}${numberWithCommas(response[index].Descuento.toFixed(2))}`;
                        response[index].PrecioUnidadShow = `${Currency}${numberWithCommas(response[index].PrecioUnidad.toFixed(2))}`;
                    }
                    this.DataTable = response;
                this.DataTable = response
            },
            assignParams(){
                if(this.datafilter){
                    this.columsConfig[0].options = this.datafilter.Warehouses;
                    this.columsConfig[1].options = this.datafilter.providers;
                    this.columsConfig[2].options = this.datafilter.Families;
                    this.columsConfig[3].options = this.datafilter.Categories;
                    this.columsConfig[4].options = this.datafilter.SubCategories;
                    this.columsConfig[5].options = this.datafilter.batches;
                    this.columsConfig[6].options = this.datafilter.invNames;
                    this.columsConfig[7].options = this.datafilter.Sellers;
                    this.Reloadfilter += 1;
                }
            }
        },
        mounted() {
            this.getAllHistory();
            this.assignParams();

        }

    }
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


</style>