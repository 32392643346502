import { render, staticRenderFns } from "./PrintAccountsReceivableHistory.vue?vue&type=template&id=c12b8e08&scoped=true"
import script from "./PrintAccountsReceivableHistory.vue?vue&type=script&lang=js"
export * from "./PrintAccountsReceivableHistory.vue?vue&type=script&lang=js"
import style0 from "./PrintAccountsReceivableHistory.vue?vue&type=style&index=0&id=c12b8e08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c12b8e08",
  null
  
)

export default component.exports