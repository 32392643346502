<template>
  <v-expansion-panel v-model="panel" class="mt-2">
    <v-expansion-panel-header>
      Ventas
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="getFilterData()" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
        <v-tabs-slider color="red"></v-tabs-slider>
          <!--v-tab href="#tab-1"
          v-if="rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 ||
                    rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 ||
                    rolesStatus.findIndex((role) => role.ID_Role == 8) > -1
                    "
          >
            historial de ventas
            <v-icon>mdi-archive-check</v-icon>
          </v-tab-->
          <v-tab href="#tab-2" v-if="$store.getters['rolesUser/getArrayCustomRole']([241]) || $store.getters['rolesUser/isAdminOrManager']">
            Reporte de ganancias
            <v-icon>mdi-currency-usd</v-icon>
          </v-tab>
          <v-tab v-if="false" href="#tab-4">
            Histórico de Cuentas <br /> por Cobrar
            <v-icon>mdi-history</v-icon>
          </v-tab>
          <v-tab v-if="false" href="#tab-5">
            Resumen de Saldos <br />vencidos por Clientes
            <v-icon>mdi-account-cancel</v-icon>
          </v-tab>
          <v-tab v-if="false" href="#tab-6">
            Resumen de Abonos <br />expirados a Vendedores 
            <v-icon>mdi-account-alert</v-icon>
          </v-tab>
         
      </v-tabs>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1" v-if="$store.getters['rolesUser/getArrayCustomRole']([241]) || $store.getters['rolesUser/isAdminOrManager']">
          <v-card flat class="pa-5 position-relative">
            <v-card-text class="pa-2">
              <SalesHistory :key="key" :datafilter="filterData"  />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card flat class="pa-1 mt-2">
            
                <v-card-text class="pa-2">
                  <EarningDetail :key="key"  :datafilter="filterData"   ></EarningDetail>
               </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-4">
          <v-card flat class="pa-5 position-relative">
            
                <v-card-text>
                  <AccountsReceivableHistory :key="key" :Sellers="filterData.Sellers"   />
               </v-card-text>
          </v-card>
        </v-tab-item> 
        <v-tab-item value="tab-5">
          <v-card flat class="pa-5 position-relative">            
                <v-card-text>
                  <OverdueBalancesCustomer :key="key" :Sellers="filterData.Sellers"   />
               </v-card-text>
          </v-card>
        </v-tab-item>
       
        <v-tab-item value="tab-6">
          <v-card flat class="pa-5 position-relative">  
                <v-card-text>
                  <ExpiredPayments :key="key" :Sellers="filterData.Sellers"   />
                </v-card-text>
          </v-card>
        </v-tab-item> 
       
      </v-tabs-items>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import EarningDetail from "@/views/inventory/reports/EarningDetail";
import SalesHistory from "../reports/SalesHistory";
// import SellersAccountsReceivable from "../reports/SellersAccountsReceivable";
import AccountsReceivableHistory from "../reports/AccountsReceivableHistory";
import OverdueBalancesCustomer from "../reports/OverdueBalancesCustomer";
import ExpiredPayments from "../reports/ExpiredPayments";
import { mapState, mapGetters } from "vuex";
export default {
  name: "InventoryReports", 
  components: {
    SalesHistory,
    EarningDetail,
    AccountsReceivableHistory,
    OverdueBalancesCustomer,
    ExpiredPayments,
  },
  props: {
    win: {
      type: Object,
      default: () => ({}),
    },
    reportModule :{
      type: Boolean,
      default: false

    }
  },
  data() {
    return {
      panel: 0,
      tab: "tab-2",
      key: 0,
      isCarsem: false,
      filterData:{
        Warehouses: [],
        Providers: [],
        Families: [],
        Categories: [],
        SubCategories: [],
        batches: [],
        providers: [],
      },
      business: {},
    };
  },
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    ...mapGetters(["getCatalogMH"])
  },
  async mounted(){
    
    await this.getFilterData();

  },
  methods: {
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
   
    
    async getFilterData() {
      try {
        const { businessid = "", branch = "" } = JSON.parse(localStorage.getItem("user"));
        const filtersWh = { business: businessid, branch };
       

        const [families, categories, subs, warehouses, batches, providers, invNames, Sellers, Customers, business, Users, Medidas ] = await Promise.all([
          this.$API.itemtypes.getFamilies(),
          this.$API.itemtypes.getCategories(),
          this.$API.itemtypes.getSubs(),
          this.$API.warehouse.getWarehouses(filtersWh),
          this.$API.products.getAllBatch(),
          this.$API.providers.getProvidersHistory(filtersWh),
          this.$API.catalogtypedocument.getCatalogTypeDocumentForProvider(),
          this.$API.sellers.getAllSellers(),
          this.$API.customers.getCustomerAccountStatus(),
          this.$API.business.FindOne(businessid),
          this.$API.users.getUsersByBranch(),
          this.getCatalogMH("UnidadMedida")
        ]);
      
        console.log('invNames', categories);
        console.log('invNames', subs);
        console.log('customers', Customers);
        this.filterData.Families = families.map((fam) => ({name: fam.name, value: fam.id}));
        this.filterData.Categories = categories.map((cat) => ({name: cat.name, value: cat.id, parent_id: cat.id_family}));
        this.filterData.SubCategories = subs.map((sub) => ({name: sub.name, value: sub.id, parent_id: sub.id_cat}));
        this.filterData.Warehouses = warehouses.map((wh) =>({name: wh.Warehouse, value: wh.ID_Warehouse}));
        this.filterData.batches = batches.map(({ID_ItemBatch}) => ({name: ID_ItemBatch, value: ID_ItemBatch}));
        this.filterData.providers = providers.map((prov) => ({name: prov.ProviderName, value: prov.ID_Provider}));
        this.filterData.invNames = invNames.data.map((inv) => ({name: inv.text, value: inv.value}));
        this.filterData.Sellers = Sellers.map((seller) => ({name: seller.FirstName + ' ' + seller.LastName  , value: seller.ID_Seller}));
        this.filterData.Customers = Customers.map((customer) => ({name: customer.LegalName  , value: customer.ID_Customer}));
        this.filterData.Users = Users.map((user) => ({name: user.FirstName + ' ' + user.LastName + ' ('+ user.UserName  +')', value: user.ID_User}))
        this.filterData.Medidas = Medidas.map((medida) => ({name: medida.Valores, value: medida.Valores}))
        this.business = business;
        this.key++;
      } catch (error) {
        // Manejar errores aquí
        console.error("Error al obtener datos:", error);
      }
    }

  },
};
</script>

<style scoped>
.show .v-list-item {
  min-height: auto !important;
}

.filter-btn {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.justified-column {
  width: 100%;
  border: 1px solid #ccc;
  /* Solo para visualizar el contenedor */
  padding: 10px;
  /* Espaciado interno */
  word-wrap: break-word;
  /* Permite el salto de palabras */
}
</style>
