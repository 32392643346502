<template>
    <div class="background rounded-lg">
        <v-row>
            <v-col cols="12">
                <v-radio-group v-model="typeData" class="ma-3">
                    <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                    <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>

        <div>
            <v-tabs v-model="tab">
                <v-tab href="#1">Ver reporte general</v-tab>
                <v-tab href="#2">Ver reporte detallado</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
                <v-tab-item value="1">
                    <VersatileFilter class="pt-3" :columnsSearch="columsConfig" :search="search" @emptyFilter="getEarningDetail" @filtered="filterHandler"
                        :key="Reloadfilter" :columnfilterEndpoint="$API.inventoryReports.getFilterEarningsColReport" @getStart=" (date) => {changueStr(date)}" @getEnd=" (date) => {changueEnd(date)}" :extraParam1="typeData">
                        <DxDataGrid :data-source="reportsData" :show-borders="true"    
                            :column-auto-width="true"  @exporting="onExporting"
                            style="max-height: 600px;" :paging="{ enabled: false }">
                            <DxScrolling 
                            row-rendering-mode="virtual" 
                            showScrollbar="always"
                            useNative="false" />
                            <DxExport :enabled="true" />
                            <DxColumn data-field="ID_Invoice" caption="ID Factura" />
                            <DxColumn data-field="InvoiceDate" caption="Fecha factura" />
                            <DxColumn data-field="InvoiceTime" caption="Hora de factura" />
                            <DxColumn data-field="ID_Customer" caption="ID CLiente" />
                            <DxColumn data-field="LegalName" caption="Nombre Legal" />
                            <DxColumn data-field="DiscountFormatted" caption="Descuento" />
                            <DxColumn data-field="PriceNotIvaFormatted" caption="Precio sin iva" />
                            <DxColumn data-field="InvoiceIvaPerce1Formatted" caption="Iva Perce" />
                            <DxColumn data-field="InvoiceIvaRete1Formatted" caption="Iva Rete" />
                            <DxColumn data-field="InvoiceReteRentaFormatted" caption="Rete Renta" />
                            <DxColumn data-field="InvoiceTotalExentaFormatted" caption="Exentas" />
                            <DxColumn data-field="InvoiceTotalNoSujFormatted" caption="No Sujetas" />
                            <DxColumn data-field="IvaFormatted" caption="Iva" />
                            <DxColumn data-field="PriceWithIvaFormatted" caption="Precio con iva" />
                            <DxColumn data-field="CostNotIvaFormatted" caption="Costo sin iva" />
                            <DxColumn data-field="CostIvaFormatted" caption="Iva del costo" />
                            <DxColumn data-field="CostWithIvaFormatted" caption="Costo" />
                            <DxColumn data-field="EarningsFormatted" caption="Ganancias" />
                            <DxColumn data-field="UserName" caption="Usuario" />
                            <DxColumn data-field="FullUserName" caption="Nombre del usuario" />
                            <DxColumn data-field="FullSellerName" caption="Nombre del vendedor" />
                            <DxColumn data-field="TipoFactura" caption="Tipo de factura" />
                            <DxColumn data-field="Zona" caption="Zona" />
                            <DxColumn data-field="NIT" caption="NIT" />
                            <DxColumn data-field="NRC" caption="NRC" />
                            <DxColumn data-field="DUI" caption="DUI" />
                            <DxColumn data-field="InvoiceType" caption="Tipo de factura" />
                            <DxColumn data-field="EstadoDTE" caption="Estado del DTE" />
                            <DxColumn data-field="DiasCredito" caption="Días de crédito" />
                            <DxColumn data-field="CodigoGeneracion" caption="Código de generación" />
                            <DxColumn data-field="NumeroControl" caption="Número de control" />
                        </DxDataGrid>
                    </VersatileFilter>
                    <div class="black--text">
                      <div class="text-h4 text-center mb-6">Totales</div>
                        <v-card
                          rounded="lg"
                          class="mx-6 mb-10 py-2"
                          color="white"
                          elevation="5"
                        >
                          <v-row justify="center">
                            <v-col cols="12" sm="2" class="py-4 px-2">
                              <div class="text-center font-weight-bold">Costos sin IVA</div>
                              <div class="text-center text-h5 font-weight-light">
                                {{ Currency + " " + summary.totalCost }}
                              </div>
                            </v-col>
                            <v-col cols="12" sm="2" class="py-4 px-2">
                              <div class="text-center font-weight-bold">Descuentos</div>
                              <div class="text-center text-h5 font-weight-light">
                                {{ Currency + " " + summary.totalDiscount }}
                              </div>
                            </v-col>
                            <v-col cols="12" sm="2" class="py-4 px-2">
                              <div class="text-center font-weight-bold">Ventas sin IVA</div>
                              <div class="text-center text-h5 font-weight-light">
                                {{ Currency + " " + summary.totalNotIva }}
                              </div>
                            </v-col>
                            <v-col cols="12" sm="2" class="py-4 px-2">
                              <div class="text-center font-weight-bold">Ventas</div>
                              <div class="text-center text-h5 font-weight-light">
                                {{ Currency + " " + summary.totalIva }}
                              </div>
                            </v-col>
                            <v-col cols="12" sm="2" class="py-4 px-2">
                              <div class="text-center font-weight-bold">Ganancias</div>
                              <div class="text-center text-h5 font-weight-light">
                                {{ Currency + " " + summary.totalEarnings }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-card>
                    </div>
                </v-tab-item>
                <v-tab-item value="2">
                    <VersatileFilter :isAutoScrolled="true" class="pt-3" :columnsSearch="columsConfig2" :search="{ getGrouping: this.showGroupingNoDetails }" @emptyFilter="getEarningDetailByItems" @filtered="filterHandler2"
                        :key="Reloadfilter2" :columnfilterEndpoint="$API.inventoryReports.getFilterEarningsByItemColReport" @getStart=" (date) => {changueStr2(date)}" @getEnd=" (date) => {changueEnd2(date)}" :extraParam1="typeData">
                        <v-row class="mt-2 ml-2">
                            <v-col cols="12" md="3">
                                <v-checkbox v-model="showGroupingDetails" @click="showGroupingNoDetails = false" label="Mostrar tabla agrupada"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-checkbox v-model="showGroupingNoDetails" @click="showGroupingDetails = false" label="Mostrar totales por familia, categoría y subcategoría"></v-checkbox>
                            </v-col>
                        </v-row>
                        <DxDataGrid v-if="showGroupingDetails == false && showGroupingNoDetails == false" 
                            :data-source="reportsData2" :show-borders="true"
                            :column-auto-width="true" @exporting="onExporting2"
                            style="max-height: 600px;" :paging="{ enabled: false }">
                            <DxScrolling 
                            row-rendering-mode="virtual" 
                            showScrollbar="always"
                            useNative="false" />
                            <DxExport :enabled="true" />
                            <DxColumn data-field="ID_Invoice" caption="ID Factura" />
                            <DxColumn data-field="InvoiceDate" caption="Fecha factura" />
                            <DxColumn data-field="InvoiceTime" caption="Hora factura" />
                            <DxColumn data-field="ID_Customer" caption="ID CLiente" />
                            <DxColumn data-field="LegalName" caption="Nombre Legal" />
                            <DxColumn data-field="ID_Item" caption="ID Producto/Servicio" />
                            <DxColumn data-field="CodigoAlterno" caption="Código alterno principal" />
                            <DxColumn data-field="ItemType" caption="Tipo" />
                            <DxColumn data-field="ItemQuantity" caption="Cantidad" />
                            <DxColumn data-field="ItemMeasureUnit" caption="Unidad de medida" />
                            <DxColumn data-field="ItemDescription" caption="Descripción" />
                            <DxColumn data-field="ItemUnitPrice" caption="Precio unitario" />
                            <DxColumn data-field="ItemDiscount" caption="Descuento" />
                            <DxColumn data-field="ItemDiscountPercent" caption="Descuento (%)" />
                            <DxColumn data-field="ItemGravada" caption="Total Con Iva" />
                            <DxColumn data-field="ItemPriceSinIVA" caption="Total Gravado" />
                            <DxColumn data-field="ItemNoSuj" caption="Total No Suj" />
                            <DxColumn data-field="ItemExenta" caption="Total Exenta" />
                            <DxColumn data-field="ItemIVA" caption="IVA" />
                            <DxColumn data-field="WarehouseName" caption="Almacén" />
                            <DxColumn data-field="familyName" caption="Familía" />
                            <DxColumn data-field="categoryName" caption="Categoría" />
                            <DxColumn data-field="subcategoryName" caption="Subcategoría" />
                            <DxColumn data-field="CostNotIva" caption="Costo sin iva" />
                            <DxColumn data-field="CostIva" caption="Iva del costo" />
                            <DxColumn data-field="CostWithIva" caption="Costo" />
                            <DxColumn data-field="Earnings" caption="Ganacia" />
                            <DxColumn data-field="UserName" caption="Usuario" />
                            <DxColumn data-field="FullUserName" caption="Nombre del usuario" />
                            <DxColumn data-field="FullSellerName" caption="Nombre del vendedor" />
                            <DxColumn data-field="Zona" caption="Zona" />
                            <DxColumn data-field="NIT" caption="NIT" />
                            <DxColumn data-field="NRC" caption="NRC" />
                            <DxColumn data-field="DUI" caption="DUI" />
                            <DxColumn data-field="InvoiceType" caption="Tipo de factura" />
                            <DxColumn data-field="EstadoDTE" caption="Estado del DTE" />
                            <DxColumn data-field="DiasCredito" caption="Días de crédito" />
                            <DxColumn data-field="CodigoGeneracion" caption="Código de generación" />
                            <DxColumn data-field="NumeroControl" caption="Número de control" />
                        </DxDataGrid>


                        <DxDataGrid v-if="showGroupingDetails == true && showGroupingNoDetails == false" class="isScrolledByGeneralFilter" :data-source="reportsData2" 
                            :show-borders="true"
                            style="max-height: 600px;" :column-auto-width="true"  @exporting="onExporting3" :paging="{ enabled: false }">
                            <DxExport :enabled="true" />
                            <DxScrolling 
                            row-rendering-mode="virtual" 
                            showScrollbar="always"
                            useNative="false" />
                            <!--DxColumn :group-index="0" data-field="WarehouseName" caption="Almacén" :sortOrder="'asc'" /-->
                            <DxColumn :group-index="0" caption="Familia: " :calculate-display-value="getCustomCell" />
                            <DxColumn data-field="ID_Invoice" caption="ID Factura" />
                            <DxColumn data-field="InvoiceDate" caption="Fecha factura" />
                            <DxColumn data-field="InvoiceTime" caption="Hora factura" />
                            <DxColumn data-field="ID_Customer" caption="ID CLiente" />
                            <DxColumn data-field="LegalName" caption="Nombre Legal" />
                            <DxColumn data-field="ID_Item" caption="ID Producto/Servicio" />
                            <DxColumn data-field="CodigoAlterno" caption="Código alterno principal" />
                            <DxColumn data-field="ItemType" caption="Tipo" />
                            <DxColumn data-field="ItemQuantity" caption="Cantidad" />
                            <DxColumn data-field="ItemMeasureUnit" caption="Unidad de medida" />
                            <DxColumn data-field="ItemDescription" caption="Descripción" />
                            <DxColumn data-field="ItemUnitPrice" caption="Precio unitario" />
                            <DxColumn data-field="ItemDiscount" caption="Descuento" />
                            <DxColumn data-field="ItemDiscountPercent" caption="Descuento (%)" />
                            <DxColumn data-field="ItemGravada" caption="Total Con Iva" />
                            <DxColumn data-field="ItemPriceSinIVA" caption="Total Gravado" />
                            <DxColumn data-field="ItemNoSuj" caption="Total No Suj" />
                            <DxColumn data-field="ItemExenta" caption="Total Exenta" />
                            <DxColumn data-field="ItemIVA" caption="IVA" />
                            <DxColumn data-field="CostNotIva" caption="Costo sin iva" />
                            <DxColumn data-field="CostIva" caption="Iva del costo" />
                            <DxColumn data-field="CostWithIva" caption="Costo" />
                            <DxColumn data-field="Earnings" caption="Ganacia" />
                            <DxColumn data-field="UserName" caption="Usuario" />
                            <DxColumn data-field="FullUserName" caption="Nombre del usuario" />
                            <DxColumn data-field="FullSellerName" caption="Nombre del vendedor" />
                            <DxColumn data-field="Zona" caption="Zona" />
                            <DxColumn data-field="NIT" caption="NIT" />
                            <DxColumn data-field="NRC" caption="NRC" />
                            <DxColumn data-field="DUI" caption="DUI" />
                            <DxColumn data-field="InvoiceType" caption="Tipo de factura" />
                            <DxColumn data-field="EstadoDTE" caption="Estado del DTE" />
                            <DxColumn data-field="DiasCredito" caption="Días de crédito" />
                            <DxColumn data-field="CodigoGeneracion" caption="Código de generación" />
                            <DxColumn data-field="NumeroControl" caption="Número de control" />

                            <DxSummary >
                                <DxGroupItem
                                :show-in-group-footer="true"
                                column="ItemGravada"
                                summary-type="sum"
                                :value-format="customCurrencyFormat"
                                display-format="Total Con Iva: {0}"
                                />
                                <DxGroupItem
                                :show-in-group-footer="true"
                                column="ItemPriceSinIVA"
                                summary-type="sum"
                                :value-format="customCurrencyFormat"
                                display-format="Total Gravada: {0}"
                                />
                                <DxGroupItem
                                :show-in-group-footer="true"
                                column="ItemNoSuj"
                                summary-type="sum"
                                :value-format="customCurrencyFormat"
                                display-format="Total No Suj: {0}"
                                />
                                <DxGroupItem
                                :show-in-group-footer="true"
                                column="ItemExenta"
                                summary-type="sum"
                                :value-format="customCurrencyFormat"
                                display-format="Total Exento: {0}"
                                />
                                <DxGroupItem
                                :show-in-group-footer="true"
                                column="ItemIVA"
                                summary-type="sum"
                                :value-format="customCurrencyFormat"
                                display-format="Total IVA: {0}"
                                />
                                <DxGroupItem
                                :show-in-group-footer="true"
                                column="CostNotIva"
                                summary-type="sum"
                                :value-format="customCurrencyFormat"
                                display-format="Total Costo sin IVA: {0}"
                                />
                                <DxGroupItem
                                :show-in-group-footer="true"
                                column="CostIva"
                                summary-type="sum"
                                :value-format="customCurrencyFormat"
                                display-format="Total IVA de costos: {0}"
                                />
                                <DxGroupItem
                                :show-in-group-footer="true"
                                column="CostWithIva"
                                summary-type="sum"
                                :value-format="customCurrencyFormat"
                                display-format="Total Costo con IVA: {0}"
                                />
                                <DxGroupItem
                                :show-in-group-footer="true"
                                column="Earnings"
                                summary-type="sum"
                                :value-format="customCurrencyFormat"
                                display-format="Total Ganancias: {0}"
                                />
                            </DxSummary>
                        </DxDataGrid>
                        <template v-if="showGroupingNoDetails == true && showGroupingDetails == false">
                            <br/>
                            <!--h3 class="ml-5" >Resumen por almacén: </h3>
                            <DxDataGrid class="isScrolledByGeneralFilter" 
                            :data-source="dataGroupWarehouse" 
                            :show-borders="true"
                            style="max-height: 600px;" :column-auto-width="true"  @exporting="onExporting" :paging="{ enabled: false }"
                            :show-empty="false">
                                <DxExport :enabled="true" />
                                <DxScrolling  
                                showScrollbar="always"
                                useNative="false"
                                />
                                <DxColumn data-field="WarehouseName" caption="Almacén" />
                                <DxColumn data-field="TotalPriceSinIVA" caption="Venta total sin IVA" />
                                <DxColumn data-field="TotalIVA" caption="Total IVA de venta" />
                                <DxColumn data-field="CostoTotalSinIVA" caption="Costo total sin IVA" />
                                <DxColumn data-field="IVATotalCosto" caption="Total IVA de costo" />
                                <DxColumn data-field="TotalCosto" caption="Total Costo" />
                                <DxColumn data-field="TotalGanancia" caption="Total Ganancia" />
                            </DxDataGrid-->

                            <br/>
                            <h3 class="ml-5">Resumen por familia, categoría y subcategoría: </h3>
                            <DxDataGrid class="isScrolledByGeneralFilter" 
                            :data-source="dataGroupFamilies" 
                            :show-borders="true"
                            style="max-height: 600px;" :column-auto-width="true"  @exporting="onExporting4" :paging="{ enabled: false }"
                            :show-empty="false">
                                <DxExport :enabled="true" />
                                <DxScrolling  
                                showScrollbar="always"
                                useNative="false"
                                />
                                <DxColumn data-field="familyName" caption="Familia" />
                                <DxColumn data-field="categoryName" caption="Categoría" />
                                <DxColumn data-field="subcategoryName" caption="Subcategoría" />
                                <DxColumn data-field="TotalPriceSinIVA" caption="Venta total sin IVA" />
                                <DxColumn data-field="TotalIVA" caption="Total IVA de venta" />
                                <DxColumn data-field="CostoTotalSinIVA" caption="Costo total sin IVA" />
                                <DxColumn data-field="IVATotalCosto" caption="Total IVA de costo" />
                                <DxColumn data-field="TotalCosto" caption="Total Costo" />
                                <DxColumn data-field="TotalGanancia" caption="Total Ganancia" />
                            </DxDataGrid>
                        </template>
                    </VersatileFilter>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>

</template>

<script>
//import GeneralFilter from '@/components/GeneralFilter';
import VersatileFilter from '@/components/VersatileFilter';
import { DxDataGrid, DxColumn, DxExport, DxScrolling, DxSummary, DxGroupItem } from 'devextreme-vue/data-grid';

//import DatePicker from '@/components/DatePicker';
import { numberWithCommas } from '@/helpers/money';
import { printToExcel } from "@/helpers/printToexcel";
    export default {
        name: "EarningDetail",
        components: {
           // GeneralFilter,
           // DatePicker,
            DxDataGrid,
            DxColumn,
            DxExport,
            VersatileFilter,
            DxScrolling, 
            DxSummary,
            DxGroupItem
        },
        props: ['win','datafilter'],
        data() {
            return {
                tab: '01',
                panel: 0,
                reportsData : [],
                reportsData2 : [],
                dataGroupWarehouse: [],
                dataGroupFamilies: [],
                typeData: '01',
                start: null,
                start2: null,
                end: null,
                end2: null,
                Reloadfilter: 0,
                Reloadfilter2: 0,
                summary: {
                    totalCost: 0.00,
                    totalDiscount: 0.00,
                    totalNotIva: 0.00,
                    totalIva: 0.00,
                    totalEarnings: 0.00
                },
                search: {}, 
                search2: {},
                Currency: '',
                columsConfig: [
                    {
                        name: 'ID_User',
                        title: 'Usuario',
                        value: '',
                        type: 'select',
                        options: [],
                    },
                    {
                        name: 'ID_Seller',
                        title: 'Vendedor',
                        value: '',
                        type: 'select',
                        options: [],
                    },
                ],
                columsConfig2: [
                    {
                        name: 'ItemType',
                        title: 'Filtrar por tipo',
                        value: '',
                        type: 'select',
                        options: [
                            { name: 'Producto', value: 'product' }, 
                            { name: 'Servicio', value: 'service' }
                        ]
                    },
                    {
                        name: 'ItemMeasureUnit',
                        title: 'Unidad de medida',
                        value: '',
                        type: 'select',
                        options: []
                    },
                    {
                        name: 'Warehouse',
                        title: 'Almacén',
                        value: '',
                        type: 'select',
                        options: []
                    },
                    {
                        name: 'family',
                        title: 'Familía',
                        value: '',
                        type: 'select',
                        options: []
                    },
                    {
                        name: 'category',
                        title: 'Categoría',
                        parent: 'family',
                        value: '',
                        type: 'select',
                        options: []
                    },
                    {
                        name: 'subcategory',
                        title: 'Subcategoría',
                        parent: 'category',
                        value: '',
                        type: 'select',
                        options: []
                    },
                    {
                        name: 'ID_User',
                        title: 'Usuario',
                        value: '',
                        type: 'select',
                        options: [],
                    },
                    {
                        name: 'ID_Seller',
                        title: 'Vendedor',
                        value: '',
                        type: 'select',
                        options: [],
                    },
                ],
                showGroupingDetails: false,
                showGroupingNoDetails: false,
            }
        },
        watch: {
            typeData: function() {
                this.getEarningDetail();
                this.getEarningDetailByItems();
            },
            showGroupingNoDetails: function() {
                this.getEarningDetailByItems();
            },
        },
        mounted() {
            try {
                this.start = new Date().toISOString().substr(0, 10);
                this.start2 = new Date().toISOString().substr(0, 10);
                this.end = new Date().toISOString().substr(0, 10);
                this.end2 = new Date().toISOString().substr(0, 10);
                this.Currency = JSON.parse(localStorage.getItem('branch')).Currency;
                console.log( 'this.Currency ', this.Currency );
                //this.getEarningDetailByItems();
                this.assignParams();
                
            } catch (error) {
                console.log( 'Error', error );
            }
        },
        methods: {
            customCurrencyFormat(value) {
                if (value == null) return ''; // Si el valor es nulo, retorna cadena vacía
                return '$' + parseFloat(value).toFixed(2); // Formato de moneda con 4 decimales
            },
            getCustomCell(rowData) {
                const familyName = rowData.familyName || 'Ninguna'; // Reemplaza null con una cadena vacía
                const categoryName = rowData.categoryName || 'Ninguna'; 
                const subcategoryName = rowData.subcategoryName || 'Ninguna';

                return `${familyName} - Categoría: ${categoryName} - Subcategoría: ${subcategoryName}`;
            },
            formatMoneyField(value){
                return `${ this.Currency }${ numberWithCommas( value ) }`;
            },
            assignParams(){
                if(this.datafilter){
                    this.columsConfig[0].options = this.datafilter.Users
                    this.columsConfig[1].options = this.datafilter.Sellers
                    
                    this.columsConfig2[1].options = this.datafilter.Medidas;
                    this.columsConfig2[2].options = this.datafilter.Warehouses;
                    this.columsConfig2[3].options = this.datafilter.Families;
                    this.columsConfig2[4].options = this.datafilter.Categories;
                    this.columsConfig2[5].options = this.datafilter.SubCategories;
                    this.columsConfig2[6].options = this.datafilter.Users;
                    this.columsConfig2[7].options = this.datafilter.Sellers;
                    this.Reloadfilter += 1;
                    this.Reloadfilter2 += 1;
                }
            },
            onExporting(e){
                const fecha = new Date().toISOString().slice(0, 10);
                printToExcel(e,`Reporte de ventas (${fecha})`)
            },
            onExporting2(e){
                const fecha = new Date().toISOString().slice(0, 10);
                printToExcel(e,`Reporte detallado de ventas (${fecha})`)
            },
            onExporting3(e){
                const fecha = new Date().toISOString().slice(0, 10);
                printToExcel(e,`Reporte detallado de ventas agrupado (${fecha})`)
            },
            onExporting4(e){
                const fecha = new Date().toISOString().slice(0, 10);
                printToExcel(e,`Reporte detallado de ventas totales por familia, categoría y subcategoría ${fecha}`)
            },
            filterHandler(data) {
                this.reportsData = data.data
                this.summary.totalCost = numberWithCommas(parseFloat(data.totalCost).toFixed(2))
                this.summary.totalDiscount = numberWithCommas(parseFloat(data.totalDiscount).toFixed(2))
                this.summary.totalNotIva = numberWithCommas(parseFloat(data.totalNotIva).toFixed(2))
                this.summary.totalIva = numberWithCommas(parseFloat(data.totalIva).toFixed(2))
                this.summary.totalEarnings = numberWithCommas(parseFloat(data.totalEarnings).toFixed(2))
            },
            filterHandler2(data) {
                this.reportsData2 = data.data
                //this.dataGroupWarehouse = data.dataGroupWarehouse
                this.dataGroupFamilies = data.dataGroupFamilies
                /*this.summary.totalCost = numberWithCommas(parseFloat(data.totalCost).toFixed(2))
                this.summary.totalDiscount = numberWithCommas(parseFloat(data.totalDiscount).toFixed(2))
                this.summary.totalNotIva = numberWithCommas(parseFloat(data.totalNotIva).toFixed(2))
                this.summary.totalIva = numberWithCommas(parseFloat(data.totalIva).toFixed(2))
                this.summary.totalEarnings = numberWithCommas(parseFloat(data.totalEarnings).toFixed(2))*/
            },
            getEarningDetail() {
                this.$API.inventoryReports.getEarningsReport({
                    start: this.start,
                    end: this.end,
                    typeData: this.typeData              
                }).then(response => {
                    this.reportsData = response.data
                    this.summary.totalCost = numberWithCommas(parseFloat(response.totalCost).toFixed(2))
                    this.summary.totalDiscount = numberWithCommas(parseFloat(response.totalDiscount).toFixed(2))
                    this.summary.totalNotIva = numberWithCommas(parseFloat(response.totalNotIva).toFixed(2))
                    this.summary.totalIva = numberWithCommas(parseFloat(response.totalIva).toFixed(2))
                    this.summary.totalEarnings = numberWithCommas(parseFloat(response.totalEarnings).toFixed(2))
                    
                }).
                catch(error => {
                    console.log(error);
                })
            },
            getEarningDetailByItems() {
                this.$API.inventoryReports.getEarningsReportByItems({
                    start: this.start2,
                    end: this.end2,
                    typeData: this.typeData,
                    getGrouping: this.showGroupingNoDetails,              
                }).then(response => {
                    this.reportsData2 = response.data
                    this.dataGroupFamilies = response.dataGroupFamilies
                    //this.dataGroupWarehouse = response.dataGroupWarehouse
                    /*this.summary.totalCost = numberWithCommas(parseFloat(response.totalCost).toFixed(2))
                    this.summary.totalDiscount = numberWithCommas(parseFloat(response.totalDiscount).toFixed(2))
                    this.summary.totalNotIva = numberWithCommas(parseFloat(response.totalNotIva).toFixed(2))
                    this.summary.totalIva = numberWithCommas(parseFloat(response.totalIva).toFixed(2))
                    this.summary.totalEarnings = numberWithCommas(parseFloat(response.totalEarnings).toFixed(2))*/
                    
                }).
                catch(error => {
                    console.log(error);
                })
            },
            changueStr(date) {
                this.start = date;
            },
            changueStr2(date) {
                this.start2 = date;
            },
            changueEnd(date) {
                this.end = date;
            },
            changueEnd2(date) {
                this.end2 = date;
            }
        }
    }
</script>

<style scoped>
    .background {
        background: white !important;
        color: white;
    }
</style>

<style>
    .dx-datagrid-rowsview .dx-row.dx-group-row td {
        text-align: left !important;
    }

    .hidden-rows .dx-datagrid-content .dx-data-row {
        display: none; /* Oculta todas las filas de datos */
    }
</style>