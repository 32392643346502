import { render, staticRenderFns } from "./InventoryReports.vue?vue&type=template&id=0b7186ef&scoped=true"
import script from "./InventoryReports.vue?vue&type=script&lang=js"
export * from "./InventoryReports.vue?vue&type=script&lang=js"
import style0 from "./InventoryReports.vue?vue&type=style&index=0&id=0b7186ef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b7186ef",
  null
  
)

export default component.exports