<template>
    <div class="white pa-4">
        <Block v-if="loading" />
        <v-col cols="12" md="12" class="d-flex justify-end">
                <v-btn class="btn-add" @click="setupPrint">
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </v-col>
        
        <VersatileFilter :columnsSearch="columsConfig" :search="search" @emptyFilter="getData" 
                 @filtered="filterHandler"
                        :key="Reloadfilter" :columnfilterEndpoint="$API.inventoryReports.getOverdueBalancesCustomerColReport"
                       
                >
            <DxDataGrid 
            :dataSource="DataTable"
            :columns="columnsList"
            @exporting="onExporting"
            :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
            :word-wrap-enabled="true"
            :showBorders="true"
            >
                <DxExport :enabled="true" />

                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                <DxPaging :page-size="10" />
            </DxDataGrid>
        </VersatileFilter>

        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        />
        <div>
            <component v-for="(item, index) in PrintList" :key="index" :is="item.component" v-bind="item.props" />
        </div>
    </div>
</template>

<script>
    
    //import {mapState} from 'vuex';
    import VersatileFilter from '@/components/VersatileFilter.vue';
    import { DxDataGrid, DxPager, DxPaging, DxExport } from 'devextreme-vue/data-grid';
    import Block from '@/components/Block.vue';
    import Alerts from '@/components/Alerts.vue';
    import { printToExcel } from "@/helpers/printToexcel";
    import PrintOverdueBalancesCustomer from "@/components/ReportsAndPrintings/SalesReports/PrintOverdueBalancesCustomer";


    export default {
        name: "OverdueBalancesCustomer",
        components: {
            DxDataGrid,
            DxPager,
            DxPaging,
            Block,
            Alerts,
            DxExport,
            VersatileFilter,
            PrintOverdueBalancesCustomer
        },
        props: ['win', 'Sellers'],
        data() {
            return {
                DataTable: [],
                PrintList: [],
                print: {
                    show: false,
                    header: {
                    },
                    data: [],
                },
                panel: 0,
                tab: null,
                key: 0,
                loading: false,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                SellerList: [],
                seller: null,
                columnsList: [
                { dataField: "InvoiceDate", caption: "Fecha Factura" },
                { dataField: "ID_Invoice", caption: "ID_Factura" },
                { dataField: "CodigoGeneracion", caption: "Codigo Generación" },
                { dataField: "ID_Customer", caption: "ID Cliente" },
                { dataField: "CustomerName", caption: "Nombre de Cliente" },
                { dataField: "PaymentEndDate", caption: "Fecha de Vencimiento" },
                { dataField: "ID_Seller", caption: "ID Vendedor" },
                { dataField: "SellerName", caption: "Nombre Vendedor" },
                { dataField: "Canceled", caption: "Cancelado" },
                { dataField: "PendingToPay", caption: "Pendiente de pago" },
                { dataField: "InvoiceTotalPagar", caption: "Total A pagar" },
              //  { dataField: "IsInvoiceExpired", caption: 'Estado Factura' },
                ],
                columsConfig: [
                    { name : "ID_Customer", title: "ID Cliente", value: "",  type: "text" },
                    { name : "CustomerName", title: "Nombre de Cliente", value: "",  type: "text" },
                    { name : "ID_Invoice", title: "ID Factura", value: "",  type: "text" },
                    { name : "TotalCanceled", title: "Total Cancelado", value: "",  type: "text" },
                    { name : "ID_Seller", title: "Vendedor", value: "",  type: "select", options: this.Sellers },                  
                    { name : "Canceled", title: "Cancelado", value: "",  type: "text" },
                    { name : "PendingToPay", title: "Pendiente de pago", value: "",  type: "text" },
                    { name : "InvoiceTotalPagar", title: "Total A pagar", value: "",  type: "text" },
               //     { name : "IsInvoiceExpired", title: "Estado Factura", value: "",  type: "text" },
                    { name : "CodigoGeneracion", title: "Codigo Generación", value: "",  type: "text" },
                ],
                Currency: JSON.parse(localStorage.getItem('branch')).Currency || "",
                search: {
                   
                },
                Reloadfilter: 0
            }
        },
        computed:{
            
        },
        mounted() {
            this.loadData();
        },
        methods: {
            loadData(){
                this.getData();
            },
            onExporting(e) {
             
              printToExcel(e, "Reporte de Saldos Vencidos ");
            },
            filterHandler(data){
                if(data.length > 0){
                    this.handleShowData(data);
                    return;
                }

                this.DataTable = [];
                
            },
            handleShowData(data){
              
                this.DataTable = data.map(item => {
                    return {
                        ID_Customer: item.ID_Customer,
                        CustomerName: item.CustomerName,
                        ID_Invoice: item.ID_Invoice,
                        InvoiceDate: item.InvoiceDate,
                        CodigoGeneracion: item.CodigoGeneracion,
                        ID_Seller: item.ID_Seller,
                        SellerName: item.SellerName,
                        PaymentEndDate: item.PaymentEndDate,
                        Canceled: this.Currency + " " + item.Canceled.toFixed(2),
                        PendingToPay: this.Currency + " " + item.PendingToPay.toFixed(2),
                        InvoiceTotalPagar: this.Currency + " " + item.InvoiceTotalPagar.toFixed(2),
                        IsInvoiceExpired: item.IsInvoiceExpired,
                    }
                });
            },
            async getData(){
                this.loading = true;
            
               

                try {
                    
                    let response = await this.$API.inventoryReports.getOverdueBalancesCustomer();
                    if(response.length > 0){
                        this.handleShowData(response);
                    }

                    
                } catch (error) {
                    this.loading = false;
                    this.showAlert('danger', 'Error', "Error al obtener los datos");
                }
                this.loading = false;
            },
            closeAlert() {
                this.alert.show = false; 
            },
            acceptAlert() {
                this.alert.show = false;
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            setupPrint(){
                this.print.data.body = this.DataTable;
                this.print.header.title = "Reporte de Saldos Vencidos ";
               
                
                this.print.show = true;
                this.PrintList.push({component: PrintOverdueBalancesCustomer, props: {
                    dataProps: this.print,
                }});
            },
           
        },

    }
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


</style>